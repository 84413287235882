import React, { useState, useEffect } from "react";
import { useTezosContext } from "../TezosContext";
import fruitensalada from "../fruitensalada.jpg";

const query_objkt = `
query objkt($id: bigint!, $address: String) {
  hic_et_nunc_token(where: {id: {_eq: $id}}) {
    supply
    token_holders(where: {holder: {address: {_eq: $address}}}) {
      quantity
    }
    swaps (where: {contract_version: {_eq: 2}}, order_by: {timestamp: desc}) {
      amount_left
      id
      price
    }
  }
}
`
const tezosmiami = 'tz2WNxPcE7JZhAFfqGEHkMtd2gcHaeiJKMWE' 

export const Home = () => {
  const [message, setMessage] = useState();
  const [swapId, setSwapId] = useState()
  const [price, setPrice] = useState()
  const [swapped, setSwapped] = useState();
  const [notSwapped, setNotSwapped] = useState();
  const [owned, setOwned] = useState();
  const [supply, setSupply] = useState();
  const  app = useTezosContext();

  const handleClaim = async(swapId, xtzAmount) => {
    try {
        setMessage('Mixing the Ensalada. . .');
        let hasAlready = await fetchGraphQL(query_objkt, 'objkt', { id: 601175, address: app.address })
        if (!hasAlready.data.hic_et_nunc_token[0].token_holders[0]?.quantity) {
        const isFruitful = await app.collect(swapId, xtzAmount);
        setMessage(isFruitful ? 'You got it!' : 'ensalada not found - please try again. . .');
        }
        else setMessage('you already ate one')
    } catch(e) {
        setMessage('ensalada not found - please try again');
        console.log('Error: ', e);
    }
    setTimeout(() => {
        setMessage(null);
    }, 3200);
};

async function fetchGraphQL(operationsDoc, operationName, variables) {
  let result = await fetch('https://api.hicdex.com/v1/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query: operationsDoc,
      variables: variables,
      operationName: operationName,
    }),
  })
  return await result.json()
}

useEffect(() => {
  const fetchData = async (address) => {
    const { errors, data } = await fetchGraphQL(query_objkt, 'objkt', { id: 601175, address:`${ address }`})
    if (errors) {
        console.error(errors)
    }
    if (address === tezosmiami) {
    setSwapped(data.hic_et_nunc_token[0].swaps[0].amount_left);
    setSwapId(data.hic_et_nunc_token[0].swaps[0].id)
    setPrice(data.hic_et_nunc_token[0].swaps[0].price)
    setNotSwapped(data.hic_et_nunc_token[0].token_holders[0]?.quantity)
    } else {
        setOwned(data.hic_et_nunc_token[0].token_holders[0]?.quantity)
        setSupply(data.hic_et_nunc_token[0].supply)
    }

  }
  fetchData(tezosmiami);
  fetchData(app.address) 
}, [app]);

return (
    <>
    <div>
    <a href="https://hicetnunc.miami/objkt/601175" target="blank" rel="noopener noreferrer" >tezos fruit ensalada</a>
  </div>
  {!app.activeAccount ? <p></p> : <p>owned: {owned || 0}</p>}
   <div>
    <img src={fruitensalada} alt="fruit ensalada"></img>
  </div>

  <p>{swapped && notSwapped && (10000-(notSwapped+swapped))}<b>/</b>{supply} collected</p>
  <button onClick={() => app.activeAccount ? handleClaim(swapId, price) :  (setMessage('sync wallet to collect'), setTimeout(() => {setMessage('')}, 3200))}>collect for {price/1000000} ꜩ </button><p/>
 <div style={{width: '80vw', textAlign: 'center', fontWeight: 'normal'}}> be about it to find out! </div>
  <p><b>{message}</b></p>
  
<a style={{fontWeight: 'bold'}} href="https://discord.gg/ZBxbrUD6PF" target="blank" rel="noopener noreferrer">join discord</a><p/>
 

     </>
);
}