import { Link } from "react-router-dom";
import "../styles.css";

export const About = () => {
   


    return (
      <>
      <div>
        <Link to='/'>/</Link>
        <p className="about">
             ...co-creating a reality of abundance and thriving together with fruit ensalada on tezos...
        </p>
        </div>
      </>
    );
  }