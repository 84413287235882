import React from "react";
import { useTezosContext } from "./TezosContext";
import { Routes, Route, Link } from "react-router-dom";
import { About } from './pages/About'
import { Home } from './pages/Home'
import "./styles.css";


function App() {

  const  app = useTezosContext();
  
  return(
    <>
    <header>
  
    <Link to="about">about</Link>
    
      {app.activeAccount &&
      <a href={`https://hicetnunc.live/tz/${app.address}`} target="blank" rel="noopener noreferrer">
       {app.name || app.address.substr(0, 5) + "..." + app.address.substr(-5)}</a>}
      <button onClick={() => !app.activeAccount ? app.sync() : app.unsync()}> 
      {!app.activeAccount ? "sync" : "unsync"}
      </button>

      </header>   
     
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes>
  
      
    </>
    )
}

export default App;
