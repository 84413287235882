import React, { useEffect, useState, useCallback} from "react";
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";

const hicdex = 'https://api.hicdex.com/v1/graphql'

const querySubjkt = `
query Subjkt($address: String!) {
  hic_et_nunc_holder(where: {address: {_eq: $address}}) {
    name
  }
}
`

async function fetchGraphQL(queryObjkts, name, variables) {
  let result = await fetch(hicdex, {
    method: 'POST',
    body: JSON.stringify({
      query: queryObjkts,
      variables: variables,
      operationName: name,
    }),
  })
  return await result.json()
}

const TezosContext = React.createContext();
const options = {
  name: 'Fruit Ensalada'
 };
  
const wallet = new BeaconWallet(options);

export const useTezosContext = () => {

  const app = React.useContext(TezosContext);
  if (!app) {
    throw new Error(
      `!app`
    );
  }
  return app;
};

export const TezosContextProvider = ({ children }) => {
  
  const [app, setApp] = React.useState("");
  const [address, setAddress] = useState("");
  const [tezos, setTezos] = useState(new TezosToolkit("https://mainnet.api.tez.ie"));
  const [activeAccount, setActiveAccount] = useState("")
  const [name, setName] = useState("")

  useEffect( () => {
     const getSynced = async () => {
        if (await wallet?.client?.getActiveAccount()) { 
          setActiveAccount(await wallet?.client?.getActiveAccount());
          const address =  await wallet.getPKH();
          setAddress(address);
          tezos.setWalletProvider(wallet);
          setTezos(tezos)
          if(address) {
            const { errors, data } = await fetchGraphQL(querySubjkt, 'Subjkt', { address: address});
           if (errors) {
             console.error(errors);
           }
           data?.hic_et_nunc_holder[0]?.name && 
           setName(data.hic_et_nunc_holder[0].name);
          }
        }
      };
      getSynced();
    }, [tezos]);
  
  async function sync() {
    app.currentUser && await app.currentUser?.logOut();
    await wallet.client.clearActiveAccount();
    await wallet.client.requestPermissions({
      network: {
        type: 'mainnet',
      },
    });
    tezos.setWalletProvider(wallet);
    setTezos(tezos)
    setAddress(await wallet.getPKH());
    setActiveAccount(await wallet?.client?.getActiveAccount());
    setApp(app) 
    return true;
  }

  async function unsync() {
    await wallet.client.clearActiveAccount();
    setActiveAccount("")
    setAddress("");
    //  window.location.reload();
  }
  async function collect(swapId, xtzAmount) {
    try {
        const v2 = await tezos.wallet.at('KT1HbQepzV1nVGg8QVznG7z4RcHseD5kwqBn');
        const op = await v2.methods
            .collect(parseFloat(swapId))
            .send({
                amount: parseFloat(xtzAmount),
                mutez: true,
                storageLimit: 310
            });
        await op.confirmation(2);
    } catch(e) {
        console.log('Error:', e);
        return false;
    }
    return true;
};

  const wrapped = { ...app, tezos, sync, unsync, activeAccount, address, name, collect};

  return (
   
    <TezosContext.Provider value={wrapped}>
           {children}
    </TezosContext.Provider>
  
  );
};

export default TezosContextProvider;